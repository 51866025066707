import PropTypes from 'prop-types';

// Components
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

// TODO: enlever la balise HEAD avec la meta pour le referencement pour la prod

function PageLayout({
  children, header, footer, slugs, subHeader, stickyHeader,
}) {
  return (
    <div>
      <Header
        data={header}
        context="default"
        sticky={stickyHeader}
        slugs={slugs}
      />
      {subHeader}

      <main className="p-relative w-100 background-color-beige">
        {children}
      </main>

      <Footer data={footer} />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.shape({}),
  footer: PropTypes.shape({}),
  slugs: PropTypes.shape({}),
  subHeader: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  stickyHeader: PropTypes.bool,
};

PageLayout.defaultProps = {
  children: null,
  header: {},
  footer: {},
  slugs: {},
  subHeader: null,
  stickyHeader: true,
};

export default PageLayout;
