const checkLexicalJSONValidity = (JSONToCheck) => {
  let isJSONValid = false;
  try {
    const json = JSON.parse(JSONToCheck);
    isJSONValid = json?.root?.children?.[0]?.children?.length > 0;
  } catch (error) {
    console.warn('Invalid JSON for Lexical');
  }

  return isJSONValid;
};

export default checkLexicalJSONValidity;
