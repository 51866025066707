/**
 * Parses JSON data and generates HTML markup based on the provided structure.
 * @param {string} json - The JSON string representing the data structure.
 * @returns {string} The HTML markup generated from the JSON structure.
 */
const serializeToHtml = (json) => {
  const data = JSON.parse(json);

  /**
   * Renders text nodes based on their formatting.
   * @param {Object} node - The text node object.
   * @returns {string} The HTML markup for the text node.
   */
  const renderText = (node) => {
    switch (node.format) {
      case 1: // strong
      case 3:
        return `<strong>${node.text}</strong>`;
      case 2: // italic
        return `<em>${node.text}</em>`;
      default:
        return `<span>${node.text}</span>`;
    }
  };

  /**
   * Renders text alignment styles.
   * @param {string} format - The text alignment format.
   * @returns {string} The CSS style for text alignment.
   */
  const renderStyle = (format) => {
    switch (format) {
      case 'left':
        return 'text-align: left;';
      case 'center':
        return 'text-align: center;';
      case 'right':
        return 'text-align: right;';
      case 'justify':
        return 'text-align: justify;';
      default:
        return '';
    }
  };

  /**
   * Renders HTML markup for each node in the JSON structure.
   * @param {Object} node - The node object.
   * @returns {string} The HTML markup for the node.
   */
  const renderNode = (node) => {
    switch (node.type) {
      case 'root':
        return node.children.map((child) => renderNode(child)).join('');
      case 'heading':
        return `<${node.tag} ${node.direction ? `dir=${node.direction}` : ''} ${node.format ? ` style="${renderStyle(node.format)}"` : ''}>${node.children.map((child) => renderNode(child)).join('')} <br></${node.tag}>`;
      case 'text':
        return renderText(node);
      case 'paragraph':
        return `<p ${node.direction ? `dir=${node.direction}` : ''} ${node.format ? ` style="${renderStyle(node.format)}"` : ''}>${node.children.map((child) => renderNode(child)).join('')} <br></p>`;
      case 'link': {
        const relAttr = node.rel ? `rel="${node.rel}"` : '';
        const targetAttr = node.target ? `target="${node.target}"` : '';
        const titleAttr = node.title ? `title="${node.title}"` : '';
        return `<a href="${node.url}" ${relAttr} ${targetAttr} ${titleAttr}>${node.children.map((child) => renderNode(child)).join('')}</a>`;
      }
      case 'listitem':
        return `<li>${node.children.map((child) => renderNode(child)).join('')}</li>`;
      case 'list':
        return `<${node.tag}>${node.children.map((child) => renderNode(child)).join('')}</${node.tag}>`;
      case 'divider':
        return '<hr>';
      case 'linebreak':
        return '<br>';
      default:
        return '';
    }
  };

  return renderNode(data.root);
};

export default serializeToHtml;
