import PropTypes from 'prop-types';
import Link from 'next/link';

// Styles
import styles from './NavigationFooter.module.css';

function NavigationFooter({ links }) {
  return (
    <ul className={styles.list}>
      {links?.length ? links.map(({ data: { name, link, triggerCookie } }) => (
        <li key={`${name}${link}`} className={styles.item}>
          {triggerCookie ? (
            <button
              type="button"
              onClick={() => window?.axeptioSDK?.openCookies()}
              className={styles.link}
            >
              {name}
            </button>
          ) : (
            <Link href={`${link}`} className={styles.link}>
              {name}
            </Link>
          )}
        </li>
      )) : null}
    </ul>
  );
}

NavigationFooter.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
        triggerCookie: PropTypes.bool,
      }),
    }),
  ),
};

NavigationFooter.defaultProps = {
  links: [],
};

export default NavigationFooter;
