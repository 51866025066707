import PropTypes from 'prop-types';
// Utils
import { cn } from 'utils';
import serializeToHtml from '../../../src/utils/lexicalParser';
import checkLexicalJSONValidity from '../../../src/utils/checkLexicalJSONValidity';

// Styles
import styles from './RichTextContent.module.css';

function RichTextContent({ content, className }) {
  if (!checkLexicalJSONValidity(content)) return null;
  const html = serializeToHtml(content);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={cn([styles.editor, className])}
      key={content}
    />
  );
}

RichTextContent.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RichTextContent.defaultProps = {
  className: '',
};

export default RichTextContent;
