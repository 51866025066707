import PropTypes from 'prop-types';

// Styles
import styles from './Quote.module.css';
import RichTextContent from '../RichTextContent/RichTextContent';

function Quote({ content, children }) {
  return (
    <div className={styles.quote}>
      <RichTextContent content={content} />
      {children}
    </div>
  );
}

Quote.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
};

Quote.defaultProps = {
  content: '',
  children: null,
};

export default Quote;
