import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import { cn } from 'utils';

// Styles
import styles from './SectionFooter.module.css';

// Components
import { Picto } from '../Picto/Picto';

function SectionFooter({ title, links, direction }) {
  return (
    <div className={styles.section}>
      <p className={styles.title}>{title}</p>
      <ul className={cn([styles.links, styles[direction]])}>
        {links?.length ? links.map(({ data: { name, link, source } }) => (
          <li key={`${name}${source}`}>
            <Link href={`${link}`} className={styles.link} name={source}>
              {source ? (
                <span className={styles.picto}>
                  <Picto icon={source.toLowerCase()} className={styles.icon} />
                  {source}
                </span>
              ) : name}
            </Link>
          </li>
        )) : null}
      </ul>
    </div>
  );
}

SectionFooter.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string,
        source: PropTypes.string,
        localName: PropTypes.string,
      }),
      type: PropTypes.string,
    }),
  ),
  direction: PropTypes.oneOf(['column', 'row']),
};

SectionFooter.defaultProps = {
  title: '',
  links: [],
  direction: 'column',
};

export default SectionFooter;
